body {
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Libre Franklin';
  src: url(/static/media/LibreFranklin-Thin.89df92fe.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url(/static/media/LibreFranklin-ExtraLight.4814a465.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url(/static/media/LibreFranklin-Regular.e3aac333.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url(/static/media/LibreFranklin-Medium.79decc58.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url(/static/media/LibreFranklin-SemiBold.92f99d3d.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url(/static/media/LibreFranklin-Bold.1244b786.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url(/static/media/LibreFranklin-ExtraBold.6850bb23.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

html,
body {
    height: 100%;
    min-height: 100%;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #CCC;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.015em;
    font-weight: 500;
    color: #fff;
}

* {
    box-sizing: border-box;
}

#container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

/* */

a {
    color: #fff;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    margin: 0;
}

ul,
ol {
    padding: 0;
    margin: 0;
    list-style: none;
}

p {
    margin: 0;
}

/* */

.table {
    display: table;
    position:relative;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 0;
}

.table > .col {
    position:relative;
    display:table-cell;
    width: auto;
    padding: 0px;
    vertical-align:top;
}

.center {
    position: relative;
    margin: 0 auto;         
}

.clear:after {
    content: '';
    display: block;
    clear: both;
}

a.area {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.rich p + p {
    margin-top: 13px;
}

/* */

.center.main {
    max-width: 1084px;
    padding: 0 30px;   
}

.center.main > div {
    position: relative;
}

/* */

div.overlay > .background,
#header .background {
    position: fixed;
    z-index: 1;
    cursor: pointer;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: #000;
     transition: opacity 0.4s ease-out;
    -webkit-transition: opacity 0.4s ease-out;
    -moz-transition: opacity 0.4s ease-out;
    -o-transition: opacity 0.4s ease-out;
}

#header .background {
    z-index: 10;
}

/* */

.test1 {
    z-index: 10;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 13px;
    background: blue;

}

.test2,
.test3 {
    z-index: 10;
    position: absolute;
    display: block;
    width: 15px;
    height: 100px;
    background: blue; 
}

.test2 {
    left: 0;
    width: 30px;
}

.test3 {
    width: 20px;
    right: 0;
}

.test1, .test2, .test3 {
    display: none;
}

#header {
    position: fixed;
    z-index: 90;
    left: 0;
    top: 0;
    width: 100%;
    padding-top: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.125em;
    text-transform: uppercase;
}

#header .logo {
    position: relative;
    z-index: 11;
    margin-left: -1px;
}

#header .navigation {
    float: right;
}

#header .navigation .shadow {
    display: none;
}

#header li {
    float: left;
}

#header li a {
    padding: 0 10px;
    opacity: 0.5;
}

#header li a:hover,
#header li a.selected {
    opacity: 1;
}

#header li:last-child a {
    padding-right: 0;
}

#header .menu {
    display: none;
    z-index: 12;
    position: absolute;
    width: 29px;
    height: 21px;
    right: -12px;
    top: -1px;
    cursor: pointer;
}

#header .menu span {
    display: block;
    position: absolute;
    left: 2px;
    top: 2px;
    width: 25px;
    height: 3px;
    background: #fff;
}

#header .menu span + span {
    top: 9px;
}

#header .menu span + span + span {
    top: 16px;
}

#header.open .menu span:nth-child(1) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: 9px;
}

#header.open .menu span:nth-child(2) {
  opacity: 0;
}

#header.open .menu span:nth-child(3) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 9px;
}

/* */

#content {
    position: relative;
    float: left;
    width: 100%;
}

.page {
    position: relative;
    float: left;
    width: 100%;
    min-height: 100vh;
}

.page .bg {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

@supports (-webkit-overflow-scrolling: touch) {
    .page .bg {
        background-attachment: scroll;
    } 
}

@media all and (-ms-high-contrast:none) {
    .page .bg {
        background-attachment: scroll;
    }

    #container {
        overflow: inherit;
    }
}

@supports (-ms-ime-align:auto) {
    .page .bg {
        background-attachment: scroll;
    }

    #container {
        overflow: inherit;
    }
}

.page:not(.duo):not(.end) {
    z-index: 3;
}

h1 {
    position: fixed;
    z-index: 2;
    width: 100%;
    left: 0;
    top: 50vh;
    -webkit-transform: translateY(-57%);
            transform: translateY(-57%);
    font-weight: 100;
    font-size: 168.2px;
    line-height: 168.2px;
    text-align: center;
}

h1 span {
    display: inline-block;
    margin-top: -22px;
    font-weight: 800;
    font-size: 0.09690844233055885850178359096314em;
    line-height: 0.09690844233055885850178359096314em;
    text-transform: uppercase;
    vertical-align: middle;
}

h1.small {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

h1.small span {
    display: block;
    font-size: 0.1em;
    line-height: 0.1em;
    letter-spacing: 0.125em;
}

.page > .content {
    position: relative;
    z-index: 4;
    margin-top: 50vh;
    min-height: 200px;
    padding-top: 50px;
    padding-bottom: 90px;
    background: #6E0000;
}

.page.duo > .content {
    margin-top: 100vh;
}

.page > .content > .shadow {
    position: absolute;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 40px; /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

.page > .content > .shadow.fixed {
    position: fixed;
}

.page > .content .copyright {
    position: absolute;
    right: 10px;
    top: -240px;
    width: 200px;
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    font-weight: 200;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0.05em;
    opacity: 0.65; 
}

.page h2 {
    margin-bottom: 40px;
    font-size: 70px;
    line-height: 70px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.05em;
    color: #c59999;
}

.page.records > .content,
.page.downloads > .content {
    background: #fff;
}

.page.records h2,
.page.downloads h2 {
    color: #595959;
}

.page.demo > .content {
    background: #262626;
}

.page.demo h2 {
    color: #be7878;
}

/* Duo */

.content.duo {
    text-align: center;
}

.content.duo .text1 .center {
    max-width: 650px;
}

.content.duo .table {
    margin-top: 100px;
}

.content.duo .table .col:nth-child(1) {
    padding-right: 45px;
}

.content.duo .table .col:nth-child(3) {
    padding-left: 45px;
}

.content.duo .table .separator {
    width: 2px;
    background: #d3b3b3;
}

.content.duo .table .rich {
    margin-top: 10px;
    color: #d3b3b3;
}

.content.duo .table span.image {
    display: inline-block;
    width: 150px;
    height: 150px;
    margin-top: -60px;
    background: black;
    border-radius: 50%;
    background-size: cover;
}

.content.duo h3 {
    margin-top: 4px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.035em;
    color: #fff;
}

.content.duo h4 {
    margin-top: 1px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #fff;
}

/* Dates */

.content.dates .center {
    max-width: 790px;
    padding: 0 30px;
}

.content.dates > .center > div > ul {
    border-top: 2px solid #A86666;
}

.content.dates > .center > div > ul > li {
    padding: 48px 0 30px 0;
    border-bottom: 2px solid #A86666;
}

.content.dates p.empty {
    text-align: center;
}

.content.dates .table .col:nth-child(1) {
    width: 130px;
}

.content.dates .date {
    width: 100px;
    margin-top: -32px;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
}

.content.dates .date span {
    display: block;
}

.content.dates .date span:nth-child(1),
.content.dates .date span:nth-child(4) {
    color: #c59999;
}

.content.dates .date span:nth-child(2) {
    margin-top: 12px;
    font-weight: 800;
    font-size: 40px;
    line-height: 32px;
    left: 0.035em;
}

.content.dates .date span:nth-child(3) {
    margin-top: 8px;
    font-weight: 700;
    letter-spacing: 0.1em;
}

.content.dates .date span:nth-child(4) {
    margin-top: 13px;
}

.content.dates h3 {
    margin-left: -2px;
    margin-top: -3px;
    font-size: 30px;
    line-height: 35px;
    font-weight: 600;
}

.content.dates span.meta {
    display: block;
    margin-top: 10px;
    color: #e2cccc;
}

.content.dates .rich {
    margin-top: 10px;
    color: #cca6a6;
}

.content.dates .more {
    margin-top: 54px;
}

.content.dates .more a {
    position: relative;
    padding-right: 30px;
    font-size: 22px;
    line-height: 32px;
    color: #a86666;
}

.content.dates .more a.all {
    margin-left: 130px;
}

.content.dates .more a.archive {
    float: right;
}

.content.dates .more svg {
    position: absolute;
    right: 0;
    top: 0;
    width: 17px;
    height: 32px;  
}

.content.dates .more a.all {
    color: #d3b3b3;
}

.content.dates .more a.all svg polyline {
    stroke: #d3b3b3 !important;
}

.content.dates .more a.all:hover {
    color: #fff;
}

.content.dates .more a.all:hover svg polyline {
    stroke: #fff !important;
}

.content.dates .more a.archive svg polyline {
    stroke: #a86666 !important;
}

.content.dates .more a.archive:hover {
    color: #d3b3b3;
}

.content.dates .more a.archive:hover svg polyline {
    stroke: #d3b3b3 !important;
}

/* Records */

.content.records .center {
    max-width: 1000px;
    padding: 0 30px;
}

.content.records > .center > div > ul > li {
    padding-bottom: 50px;
}

.content.records > .center > div > ul > li:last-child {
    padding-bottom: 0;
}

.content.records > .center > div > ul > li + li {
    padding-top: 50px;
    border-top: 2px solid #999999;
}

.content.records .table .col:nth-child(1) {
    padding-right: 20px;
}

.content.records .table .col:nth-child(2) {
    padding-left: 20px;
}

.content.records .image {
    margin-top: 6px;
}

.content.records .image span {
    display: block;
    background-size: cover;
}

.content.records h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #be7878;
}

.content.records h3 span {
    font-weight: 400;
}

.content.records .table .col:nth-child(1) h3 {
    display: none;
}

.content.records .meta {
    display: block;
    margin-top: 11px;
    font-size: 13px;
    line-height: 18px;
    color: #595959;
}

.content.records .meta a {
     color: #595959;
}

.content.records .rich {
    margin-top: 15px;
}

.content.records  p {
    color: #262626;
}

.content.records .rich + .player {
    margin-top: 30px;
}

.content.records .player .track {
    height: 2px;
    background: #BE7878;
}

.content.records .player .track.empty {
    background: #cccccc;
}

.content.records .player .track > span {
    display: block;
    margin-left: -2px;
    margin-right: -2px;
}

.content.records .player .track .position {
    display: block;
    position: absolute;
    margin-top: -7.7px;
    width: 17px;
    height: 17px;
    background: #BE7878;
    border-radius: 50%;
    cursor: pointer;
}

.content.records .player .track .position img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.content.records .player p {
    margin-top: 9px;
    font-size: 14px;
    line-height: 16px;
    color: #595959;
}

.content.records .player + .player {
    margin-top: 20px;
}

@media (pointer: coarse) {

    .content.records .player .track .position {
        width: 25px;
        height: 25px;
        margin-top: -11.5px;
    }

    .content.records .player p {
        margin-top: 14px;
    }

}

/* Demo */

.content.demo > .center {
    padding: 0 150px;
    max-width: 1324px;
}

.content.demo .slider .media {
    position: relative;
    border: 2px solid #be7878;
}

.content.demo .slider .media > div {
    position: relative;
    overflow: hidden;
    float: left;
    width: 100%;
    padding-bottom: 55.434782608695652173913043478261%;
}

.content.demo .slider .media > div > ul {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    will-change: transform; 
}

.content.demo .slider:not(.touching) .media > div > ul {
    transition: -webkit-transform 0.4s ease-out;
    transition: transform 0.4s ease-out;
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
    -webkit-transition: transform 0.4s ease-out;
    -moz-transition: transform 0.4s ease-out;
    -o-transition: transform 0.4s ease-out;
}


.content.demo .slider .media ul li {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.content.demo .slider .media ul li .play {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: url(/static/media/playBig.46f3ca1d.svg) no-repeat center center;
    background-size: 10% auto;
    opacity: 0.6;
}

.content.demo .slider .media ul li .play:hover {
    opacity: 0.9;
}

.content.demo .button {
    position: absolute;
    z-index: 20;
    cursor: pointer;
    background: url(/static/media/arrow.958e9b4b.svg);
    background-repeat: no-repeat;
}

.content.demo .button.previous {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); 
}

.content.demo .slider .media .button {
    width: 28px;
    height: 53px;
    top: 50%;
    margin-top: -27px;
}

.content.demo .slider .media .button.previous {
    left: -93px;
}

.content.demo .slider .media .button.next {
    right: -93px;
}

.content.demo .slider .text {
    position: relative; 
    margin-top: 30px;
    transition: all 0.2s ease-out;
    text-align: center;
    color: #be7878;
}

.content.demo .slider .navigation {
    position: relative;
    margin-top: 35px;
    text-align: center;
}

.content.demo .slider .navigation li {
    display: inline-block;
    position: relative;
    width: 15px;
    height: 15px;
    opacity: 0.5;
}

.content.demo .slider .navigation li span {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #be7878;
}

.content.demo .slider .navigation li.selected {
    opacity: 1;
}

.content.demo .slider .navigation .button {
    display: none;
    width: 20px;
    height: 38px;
}
 
/* Programs */

.content.programs {
    text-align: center;
}

.content.programs ul {
    border-top: 2px solid #A86666;
    -webkit-background-clip: padding-box; 
    background-clip: padding-box; 
}

.content.programs li {
    position: relative;
    padding: 20px 30px 24px 30px;
    border-bottom: 2px solid #A86666;
    -webkit-background-clip: padding-box; 
    background-clip: padding-box; 
}

.content.programs li:hover,
.content.programs li.selected {
    background: #8B3333;
    background: #4d0000;
}

.content.programs li:hover:before,
.content.programs li:hover:after,
.content.programs li.selected:before,
.content.programs li.selected:after {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    content: " ";
    font-size: 0;
}

.content.programs li:hover:before,
.content.programs li.selected:before {
    top: -2px;
}

.content.programs li:hover:after,
.content.programs li.selected:after {
    bottom: -2px;
}

.content.programs h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.03em;
}

.content.programs h4 {
    font-weight: 500;
    color: #d3b3b3;
}

/* Downloads */

.content.downloads > .center {
    padding: 0 30px;
    max-width: 890px;
}

.content.downloads .documents {
    border-top: 2px solid #CCCCCC;
    padding: 28px 0 28px 0;
    text-align: center;
}

.content.downloads .documents ul {
    display: inline-block;
    width: auto;
}

.content.downloads .documents ul li {
    width: auto;
    text-align: left;
}

.content.downloads .documents ul li + li {
    margin-top: 10px;
}

.content.downloads .documents a {
    display: block;
    padding-left: 21px;
    background-size: 15px 18px;
    text-align: left;
    color: #262626;
} 

a.file {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAASCAYAAACEnoQPAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGhJREFUeNpi/P//PwO5gAVEMDIyEmUC0CJGDM0g8O3bN5yauLi4wPIgS4A0IycnJ1iciRRnggwAGgR3JROp/oS5AMXZhADI6VgDjBjbsBnExEABGNU8MjQzgvIzsVkSPXsyUlIYAAQYAKphJgSGq/I3AAAAAElFTkSuQmCC) no-repeat 0px 3px;
}

a.file.pdf {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAASCAYAAACEnoQPAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAa9JREFUeNqcU7tKA1EQPXcVxJgHvoP4+AE1ReooiIhNbCz8BrHQUhDBxsLeFIqVImhjJ1hZiEIMgoIGFUQkkWgKX1GyyWaTcWfjbnbdIOqB2dedc86dubOCiPBf1PJFCPFbBWE8sGmt8ZJKpVAqlSpZQughSRL8fr+e/GUibM6M56Fxh02eSvDv78DqZhUwyXdKruo+W1TVLpiVUeeq1wVM8kuxUJVslMIlVG0YIy5/OBZdUg0GtJoTiQTS6TSy2SzcbjeCwWBZ0KgjFovZiHR6BkxOoXVmGo3zc2YDObxeL9df2TZ31AqlsQmvWmVSc7ONyN13bNvj8dgPdGQYaZLQMBbWCVYBA6YMJ1jj/SiKjLb8tLvnWHM4Wz+yenx2Hl1Li7iKrODhMApvoA+kmbYNhn4mX0dWkTy/xMfmNlyBfkg93bjY2MZbIon2gyhsU8M3WZbp9eGRtkKjtOzrpPvjE8rn83ooikKFQoGebm5JVVUq06hC5qTN8AQtwEcna+smgYMJxWKRtIHRwyCb56wl4eUuiVwmg45Ar+3n+D5h/MznLCzD/ieY5P/iU4ABAH4Z3+eaQXQoAAAAAElFTkSuQmCC);
}

a.file.txt {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAASCAYAAACEnoQPAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAQlJREFUeNqckruuwjAMhu205dKnYOYJeP+JEYFAiAUQTByBUKuGW1GJ3cQkguEQp03sSPbnPwk2TQOxltKEiK4Cmv+b7wx9mHJO/XjQ0iyXK3g+a/HrmvdBa817BhSSedeMzXYHWZpx7HylpD4XyPOcCnAH7WT8+/1GK9DgzxJoVSpxZDZTAEweCtlEH2Ip0WmkBPh2AEQeLzb/PuXRcMBkSb5edNuqbZ0lIEjsSyEZQduT9eHnexayripAWz3swNI5bv0kSUPydPsXT67K0hI8umgP9adZFpJnu2M8uSwKQHu30gHgW69H73S6IXm+P8WTi/PZUn0ieE/2Te/2+uEj+ZUqybH2EmAAE0qP4PC07eMAAAAASUVORK5CYII=);
}

a.file.doc,
a.file.docx {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAASCAYAAACEnoQPAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAZNJREFUeNqkVL1Lw0AU/6WtIAiCRERBxTGrgoh0cnVwEhwcROg/4ObYVf8AF3ESJzcRBMFBJ0sEBbEOFqFIA8V+2HimCUku3p3mSJoofjy4ewnv/T4u74gSBAH+Gjm+KYryIwYmpCTAPNZKnY8GGrAmCmGILcc0cbg4BcuyhEiUQIKp54v8arThu114jg2vS2B3WlKpl0CCvfKlyK2mCepSUJ+7yLDlxaxHCfguXkxmj4fv+7FmSilUVU09vwQTQlLBPAzDgK7rcF2Xg1AoFARY2mYEImcyGbg+xfFVFeVaG6TrYmlmHPl8HrZtC4LEBwvBPJ9cP6FlUxRX5qA/NrB7do+d9flYXwzMFcOiNjEM7fO8xPZhBTlZD7N4jiqHrNrYIKoNgo19HeX6G4Jcn6ynKkfPvHlQwuToELZWZ/FMXFxUmjFnCWVeDNdD04HNbpfx4uD0rg42FLBvKOtf2ubF4vI0bmoEe+cVLGgjUAf6sX10m7At5xydb7Sh52KInM1m0+f8XfT2JEb1m1D+8zN4F2AAFrTSn8jtWhAAAAAASUVORK5CYII=);
}

.content.downloads .documents a .meta {
    font-size: 14px;
    color: #808080;
    white-space: nowrap;
}

.content.downloads .documents a:hover span:not(.meta) {
    border-bottom: 1px solid #262626; 
} 

.content.downloads .photos {
    position: relative;
    overflow: hidden;
    border: 2px solid #CCCCCC;
    border-right: none;
    border-left: none;
}

.content.downloads .photos ul {
    margin: 0 -20px;
}

.content.downloads .photos li:not(.separator) {
    float: left;
    position: relative;
    width: 33.33333%;
    padding: 38px 20px 32px 20px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
}

.content.downloads .photos li.separator {
    float: left;
    width: 100%;
    height: 2px;
    background: #CCCCCC;
}

.content.downloads .photos li.separator.col2 {
    display: none;
}

.content.downloads .photos .image {
    padding-bottom: 66.67%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.content.downloads .photos p {
    margin-top: 12px;
    color: #262626;
}

.content.downloads .photos .meta {
    display: block;
    color: #808080;
}

/* */

.content.contact {
    text-align: center;
}

.content.contact > .center {
    max-width: 760px;
}

.content.contact h3 {
    position: relative;
    margin-bottom: 11px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
}

.content.contact .rich a[href^="mailto"]:hover {
     border-bottom: 1px solid #fff;
}

.content.contact .imprint  {
    margin-top: 48px;
    border-top: 2px solid #b78080;
}

.content.contact .imprint h3 {
    display: inline-block;
    margin-top: 40px;
    padding-right: 40px;
    cursor: pointer;
    color: #c59999;
}

.content.contact .imprint h3 svg {
    position: absolute;
    top: 0;
    right: 0;
    height: 31px;
    width: 17px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.content.contact .imprint.open h3 svg {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.content.contact .imprint .rich {
    font-size: 14px;
    line-height: 21px;
    color: #d3b3b3;
}

.content.contact .imprint .rich p + p {
    margin-top: 15px;
}

.content.contact .imprint .rich a {
    color: #d3b3b3;
}

.content.contact .imprint .rich a[href^="mailto"]:hover {
    text-decoration: none;
    color: #fff;
}

.content.contact .imprint h3:hover {
    color: #fff;
}

.content.contact .imprint h3 svg polyline {
    stroke: #c59999 !important;
}

.content.contact .imprint h3:hover svg polyline {
    stroke: #ffffff !important;
}

.content.contact .imprint .container {
    position: relative;
    margin-top: 15px;
    margin-bottom: -15px;
    overflow: hidden;
    transition: all 0.4s ease-out;
    -webkit-transition:all 0.4s ease-out;
    -moz-transition:all 0.4s ease-out;
    -o-transition:all 0.4s ease-out;
}

/* Overlay */

div.overlay {
    position: absolute;
    z-index: 100;
    width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
}

div.overlay > .content {
    position: absolute;
    z-index: 2;
    right: 0%;
    min-height: 100%;
    background: #8b3333;
    background: #6E0000;
    width: 100%;
    padding-right: 30px;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: -webkit-transform 0.4s ease-out;
    transition: transform 0.4s ease-out;
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
    -webkit-transition: transform 0.4s ease-out;
    -moz-transition: transform 0.4s ease-out;
    -o-transition: transform 0.4s ease-out;
    
} 

div.overlay > .content .shadow,
.navigation .shadow {
    position: absolute;
    display: block;
    z-index: -1;
    width: 50px;
    height: 100%;
    left: -50px;
    top: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.4+100 */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=1 ); /* IE6-9 */
    pointer-events: none;
}

div.overlay > .close {
    position: fixed;
    z-index: 3;
    width: 100%;
    right: 0%;
    top: 20px;
    padding-right: 30px;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
     transition: -webkit-transform 0.4s ease-out;
     transition: transform 0.4s ease-out;
     transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
    -webkit-transition: transform 0.4s ease-out;
    -moz-transition: transform 0.4s ease-out;
    -o-transition: transform 0.4s ease-out;
}

@media screen and ( min-width: 910px ){

    div.overlay > .content,
    div.overlay > .close {
        width: 880px;
    }

}


div.overlay > .content > div {
    padding: 0 8.5% 80px 8.5%;
}

div.overlay .close > span {
    display: block;
    position: relative;
    float: right;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

div.overlay .close > span > span {
    display: block;
    position: absolute;
    top: 9px;
    left: -3px;
    width: 26.6px;
    height: 2.7px;
    background: #fff;
}

div.overlay .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 65px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    margin-left: -15px;
}

div.overlay .loading::after {
    content: "";
    -webkit-animation: loadingAnimation 1.6s infinite;
            animation: loadingAnimation 1.6s infinite;
}

@-webkit-keyframes loadingAnimation {
    0%  { content: ""; }
    25% { content: "."; }
    50% { content: ".."; }
    75% { content: "..."; }
}

@keyframes loadingAnimation {
    0%  { content: ""; }
    25% { content: "."; }
    50% { content: ".."; }
    75% { content: "..."; }
}


div.overlay .close > span > span:nth-child(1) {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); 
}

div.overlay .close > span >  span:nth-child(2) {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

div.overlay h2 {
    margin: 46px 0 46px 0;
    font-size: 50px;
    line-height: 50px;
    color: #c59999;
    text-align: center;
}

div.overlay .program {
    text-align: center;
}

div.overlay .program h3 {
    margin-top: 83px;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
}

div.overlay .program h4 {
    margin-top: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #d3b3b3;
}

div.overlay .program .rich {
    margin-top: 30px;
}

div.overlay .program .download {
    margin-top: 26px;
    margin-bottom: 26px;
    margin-left: 5%;
    width: 90%;
    padding: 26px 0;
    border: 2px solid #b78080;
    border-left: none;
    border-right: none;
}

div.overlay .program .download p {
    color: #d3b3b3;
}

div.overlay .program .download a {
    display: inline-block;
    padding-left: 21px;
    text-align: left;
} 

div.overlay .program .download a .meta {
    font-size: 14px;
    opacity: 0.5;
    white-space: nowrap;
}

div.overlay .program .download a:hover span:not(.meta) {
    border-bottom: 1px solid #fff;
}

div.overlay .program > ul > li {
    display: block;
}

div.overlay .program .separator {
    height: 26px;
    margin-top: 8px;
    margin-bottom: 8px;
}

div.overlay .program .separator.pause {
    margin-top: 16px;
    margin-bottom: 16px;
}

div.overlay .program .separator > span {
    display: inline-block;
    position: relative;
    height: 26px;
    line-height: 26px;
    color: #b78080;
}

div.overlay .program .separator.pause > span {
    padding: 0 13px;   
}

div.overlay .program .separator span span {
    position: absolute;
    top: 12px;
    display: block;
    width: 25px;
    height: 2px;
    background: #b78080;
}

div.overlay .program .separator span span:nth-child(1) {
    right: 100%;
}

div.overlay .program .separator span span:nth-child(2) {
    left: 100%;
}

div.overlay .program > ul > li .composer,
div.overlay .program > ul > li p {
    font-size: 14px;
    line-height: 20px;
    color: #d3b3b3;

}

div.overlay .program > ul > li .composer {
    display: block;
    margin-bottom: 3px;
}

div.overlay .program > ul > li .composer span {
    font-size: 12px;
    color: #b78080;
}

div.overlay .program > ul > li h5 {
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
}

div.overlay .content.dates .center {
    padding: 0;
}

@media screen and ( max-width: 1023px ){

    #header .menu {
       display: block;
    }

    #header .navigation {
        position: fixed;
        z-index: 11;
        right: -350px;
        top: 0;
        height: 100%;
        width: 290px;
        background: rgba(110, 0, 0, 0.95);
        margin-right: 0px; 
    transition: -webkit-transform 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940); 
    transition: transform 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940); 
    transition: transform 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940), -webkit-transform 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    
    }

    #header.open .navigation {
        -webkit-transform: translateX(-350px);
                transform: translateX(-350px);
    }

    #header .navigation ul {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 50px 0 50px 30px;
        font-size: 24px;
        line-height: 30px;
        overflow-y: auto;
    }

    #header .navigation li {
        float: none;
    }

    #header .navigation li + li {
        margin-top: 20px;
    }

    #header .navigation li a {
        padding: 0 0;
        opacity: 1;
    }

    #header .navigation .shadow {
        display: none;
    }

    .content.records .table .col:nth-child(1) {
        padding-right: 15px;
    }

    .content.records .table .col:nth-child(2) {
        padding-left: 15px;
    }

    .content.downloads .photos ul {
        margin: 0 -15px;
    }

    .content.downloads .photos li:not(.separator) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and ( max-width: 800px ){

    .content.duo .text1 {
        padding-bottom: 30px;
        border-bottom: 2px solid #d3b3b3;
    }

    .content.duo .table {
        margin-top: 30px;
    }

    .content.duo .table .col {
        display: block;
    } 

    .content.duo .table .col:nth-child(1) {
        padding-right: 0;
        padding-bottom: 30px;
    }

    .content.duo .table .col:nth-child(3) {
        padding-left: 0;
        padding-top: 30px;
    }

    .content.duo .table .separator {
        height: 2px;
        width: 100%;
    }

    .content.duo .table span.image {
        margin-top: 0;
    }

    /* */

    .content.records .table .col {
        display: block;
    }

    .content.records .table .col:nth-child(1) h3 {
        display: block;
        text-align: left;
        margin-bottom: 19px;
    }

    .content.records .table .col:nth-child(1) {
        padding-right: 0;
        text-align: center;
    }

    .content.records .table .col:nth-child(2) {
        margin-top: 10px;
        padding-left: 0;
    }

    .content.records .table .col:nth-child(2) h3 {
        display: none;
    }

    .content.records .center {
        max-width: 560px;
    }

    .content.records .image {
        display: inline-block;
        width: 100%;
        margin-top: 0px;
    }

    .content.records .meta {
        margin-top: -6px;
    }

    .content.records .rich {
        margin-top: 24px;
    }

    /* */

    .content.downloads .photos li:not(.separator) {
        width: 50%;
    }

    .content.downloads .photos li.separator.col3 {
        display: none;
    }

    .content.downloads .photos li.separator.col2 {
        display: block;
    }

    /* */

    .content.demo > .center {
        padding: 0 30px;
    }

    .content.demo .slider .button {
        display: none;
    }

    .content.demo .slider .text{
        font-size: 14px;
        line-height: 22px;
    }

    .content.demo .slider .navigation .button {
        display: block;
        top: -7px;
    }

    .content.demo .slider .navigation .button.previous {
        left: 0;
    }

    .content.demo .slider .navigation .button.next {
        right: 0;
    }

    /* */

    .content.dates h3 {
        margin-left: -1px;
        margin-top: 0px;
        font-size: 24px;
        line-height: 30px;
    }

}

@media screen and ( max-width: 600px ){

    .test2 {
        width: 15px;
    }

    .test3 {
        width: 15px;
    }

    body {
        font-size: 15px;
        line-height: 22px;
    }

    .center.main,
    .content.dates .center,
    .content.records .center,
    .content.demo > .center,
    .content.downloads > .center{
        padding: 0 20px;
    }

    .page:not(.duo):not(.end):not(.loading) {
        min-height: inherit;
    }

    .page:not(.duo):not(.end):not(.loading) .bg {
        height: 40vh;
         background-attachment: scroll;
    }

    .page:not(.duo):not(.end):not(.loading) > .content {
        margin-top: 40vh;
    }

    a.file {
        background-position: 0 1px;
    }

    .rich p + p {
        margin-top: 11px;
    }

    .content.programs li {
        padding-left: 20px;
        padding-right: 20px;
    }

    #header .logo {
        margin-left: -6px;
    }

    #header .menu {
        right: -7px;
    }

    #header .navigation {
        margin-right: -30px;
    }

    .page > .content .copyright {
        right: 5px;
    }

    .page h2 {
        font-size: 40px;
        line-height: 40px;
    }

    .content.dates .table .col:nth-child(1) {
        width: 60px;
    }

    .content.dates .date {
        width: 48px;
    }

    .content.dates .date span:nth-child(2) {
        font-size: 32px;
        line-height: 24px;
        margin-top: 10px;
    }

    .content.dates .date span:nth-child(1), 
    .content.dates .date span:nth-child(4),
    .content.dates .date span:nth-child(3) {
        font-size: 13px;
        line-height: 13px;
    }

    .content.dates h3 {
        margin-top: -6px;
        font-size: 20px;
        line-height: 26px;
    }

    .content.dates .more {
        padding-left: 60px;
        width: 300px;
    }

    .content.dates .more a.all {
        margin-left: 0px;
    }

    .content.dates .more a.archive {
        float: left;
        margin-top: 20px;
    }

    .content.downloads .photos li:not(.separator) {
        padding-left: 10px;
        padding-right: 10px;
    }

    div.overlay > .content > div {
        padding: 0 20px 80px 20px;
    }

    div.overlay h2 {
        font-size: 40px;
        line-height: 40px;
    }

    div.overlay .program h3 {
        font-size: 30px;
        line-height: 36px;
    }

    div.overlay .program h4 {
        font-size: 20px;
        line-height: 26px;
    }
}   

@media screen and ( max-width: 500px ){

    #header .logo {
        display: block;
        width: 80px;
    }

    .page > .content > .shadow {
        height: 70px;
    }

    div.overlay .program .download {
        margin-left: 0;
        width: 100%;
    }
}


